<template>
	<div class="flex-row align-items-center">
		<CRow class="justify-content-center headimg mb-5">
			<CCol md="12" class="p-0 ">
				<div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 200px;">
					<div class="middle-center text-center text-white">
						<h2 class=""><b>Formulir Permohonan <span class="text-success">{{module}}</span></b></h2>
						<h4>{{kategori_module}}</h4>
					</div>
				</div>
			</CCol>
		</CRow>
		<div class="container">
			<CRow class="justify-content-center">
				<CCol md="12">
					<CCard class="top-radius-15">
						<CCardHeader class="top-radius-15 bg-success text-white">
							<h4 class="my-0 text-center"><b>Permohonan</b></h4>
						</CCardHeader>
						<CCardBody>
							<table class="w-100 table-tr-spacing">
								<tbody>
									<tr>
										<th style="width: 250px;"><label for=""><b>No. Surat Permohonan <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td><input type="text" class="form-control" placeholder="No. Surat Permohonan" v-model="pr_nomor_surat"></td>
									</tr>
									<tr>
										<th style="width: 250px;"><label for=""><b>Tanggal Surat <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td><input type="date" class="form-control" placeholder="Tanggal Surat" v-model="pr_tanggal"></td>
									</tr>
									<tr>
										<th style="width: 250px;"><label for=""><b>Perihal <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td><textarea rows="5" class="form-control" placeholder="Perihal" v-model="pr_perihal" readonly=""></textarea></td>
									</tr>
									<tr v-if="list_penanggung_jawab.length != 0">
										<th style="width: 250px;"><label for=""><b>Penanggung Jawab <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td>
											<v-select 
                                                :options="list_penanggung_jawab"
                                                label="nama_penanggung_jwb"
                                                v-model="penanggung_jawab"
                                                placeholder="Pilih Penanggung Jawab"
                                            >
												<template v-slot:option="value_list_penanggung_jawab">
													{{ value_list_penanggung_jawab.nama_penanggung_jwb }} - {{ value_list_penanggung_jawab.jabatan_penanggung_jwb }}
												</template>
												<template #selected-option="value_list_penanggung_jawab">
													{{ value_list_penanggung_jawab.nama_penanggung_jwb }} - {{ value_list_penanggung_jawab.jabatan_penanggung_jwb }}
												</template>
											</v-select>
										</td>
									</tr>
									<tr>
                                        <td colspan="3">
                                            <div class="alert alert-info alert-sm" v-if="list_penanggung_jawab.length != 0">
                                                Nama penanggung jawab diambil dari oss, apabila nama penanggung jawab tidak tersedia, mohon cek aplikasi kemenkumham
                                            </div>
                                        </td>
                                    </tr>



									


									
									
									<tr>
										<th style="width: 250px;">
											<label for="">
                                                <b>Data benih <span class="text-danger">*</span> 
                                                    <i class="fa fa-info-circle pl-2 text-info" 
                                                    v-c-popover="{
                                                        header: false,
                                                        content: 'Jika jenis tanaman tidak ditemukan, hubungi PVTPP',
                                                        placement: 'right-end'
                                                      }"
                                                    ></i>
                                                </b>
                                            </label>
										</th>
										<td style="width: 15px;"> : </td>
										<td>Masukan data benih pada table dibawah</td>
									</tr>
									<tr>
										<td colspan="3">
											<table class="table table-bordered table-sm" id="table-benih">
												<thead class="bg-sapphire">
													<tr>
														<th>Jenis Tanaman</th>
														<th>Nama Latin</th>
														<th>Hibrida/Inbrida/Komposit</th>
														<th>Nama Varietas</th>
														<th>Nama Induk</th>
														<th>Bentuk Benih</th>
														<th>Jumlah</th>
														<th>Satuan</th>
														<th>Nilai <span>(Total Harga dalam USD)</span></th>
														<th>Kode HS</th>
														<td>
															<div class="btn btn-sm btn-success" v-on:click="addRowBenih"><i class="fa fa-plus"></i></div>
														</td>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(value_rows_benih_raw, key_rows_benih_raw) in rows_benih_raw" :key="key_rows_benih_raw">
														<td>
															<v-select
															:options="list_tanaman"
															label="t_nama"
															v-model="value_rows_benih_raw.tanaman_id"
															@input="changeJenisTanaman($event, key_rows_benih_raw)"
															:value="$store.myValue"
															placeholder="Pilih Jenis Tanaman"
														></v-select>
															
														</td>
														<td>
															<input type="text" class="form-control" v-model="value_rows_benih_raw.pb_nama_latin" readonly="readonly">
														</td>
														<td>
                                                            <div v-if="value_rows_benih_raw.is_select_hibrida == true">
	                                                            <v-select :options="['Hibrida','Inbrida','Komposit']" placeholder="Pilih Hibrida/Inbrida/Komposit" v-model="value_rows_benih_raw.pb_hibrida_inbrida_komposit" :disabled="value_rows_benih_raw.is_select_hibrida != true"></v-select>
															</div>
                                                        </td>
														<td>
															<input type="text" class="form-control" v-model="value_rows_benih_raw.pb_varietas">
														</td>
														<td>
															<input type="text" class="form-control" v-model="value_rows_benih_raw.pb_nama_induk">
														</td>
														<td>
															<v-select :options="list_bentuk_benih" label="bb_nama" v-model="value_rows_benih_raw.bentuk_benih_id" placeholder="Pilih Bentuk Benih"></v-select>	
														</td>
														<td>
															<input type="number" class="form-control" v-model="value_rows_benih_raw.pb_jumlah">
														</td>
														<td>
															<v-select :options="list_satuan" label="s_nama" v-model="value_rows_benih_raw.satuan_id" placeholder="Pilih Satuan"></v-select>	
														</td>
														<td>
															<input type="number" class="form-control" v-model="value_rows_benih_raw.pb_nilai_total">
														</td>
														<td>
															<input type="text" class="form-control" v-model="value_rows_benih_raw.pb_kode_hs">
														</td>
														<td>
															<div class="btn btn-sm btn-danger" v-on:click="deleteRow(key_rows_benih_raw, value_rows_benih_raw)"><i class="fa fa-minus"></i></div>
														</td>
													</tr>
												</tbody>
											</table>
											<small>*) Pastikan data benih terisi lengkap</small>
											<input type="hidden" id="benihcount" value="0">
										</td>
									</tr>
									<tr v-if="tujuan_pemasukan_id == 3 && is_sayur_kentang">
										<td colspan="3">
											<table class="table table-bordered table-sm" id="table-benih">
												<thead class="bg-sapphire">
													<tr>
														<th>Nama Kelompok Tani</th> 
														<th>NIK</th> 
														<th>NO. HP</th> 
														<th>Titik Koordinat</th> 
														<th>Keb Benih</th> 
														<th>Luas Lahan</th> 
														<td>
															<div class="btn btn-sm btn-success" v-on:click="addRowKelompokTani"><i class="fa fa-plus"></i></div>
														</td>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(value_row_kelompok_tani, key_row_kelompok_tani) in row_kelompok_tani" :key="key_row_kelompok_tani">
														<th>
															<input type="text" class="form-control" v-model="value_row_kelompok_tani.nama_kelompok_tani">
														</th> 
														<th>
															<input type="text" class="form-control" v-model="value_row_kelompok_tani.nik">
														</th> 
														<th>
															<input type="text" class="form-control" v-model="value_row_kelompok_tani.no_hp">
														</th> 
														<th>
															<input type="text" class="form-control" v-model="value_row_kelompok_tani.titik_koordinat">
														</th> 
														<th>
															<input type="text" class="form-control" v-model="value_row_kelompok_tani.kebun_benih">
														</th> 
														<th>
															<input type="text" class="form-control" v-model="value_row_kelompok_tani.luas_lahan">
														</th> 
														<td>
															<div class="btn btn-sm btn-danger" v-on:click="deleteRowKelompokTani(key_row_kelompok_tani, value_row_kelompok_tani)"><i class="fa fa-minus"></i></div>
															
														</td>
													</tr>
												</tbody>
											</table>

										</td>
									</tr>
									
									<tr>
										<th style="width: 250px;"><label for=""><b>Perlakuan Fisik/Kimia <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td><input type="text" class="form-control" placeholder="Contoh : Fungisida, Bebas Tanah, Perendaman pestisida, Perendaman air hangat" v-model="pr_perlakuan"></td>
									</tr>
									<tr>
										<th style="width: 250px;"><label for=""><b>Negara Asal Benih <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td>
											<v-select :filter="searchNegara" :options="list_negara" v-model="negara_asal" placeholder="Pilih Negara Asal" >
												<template v-slot:option="value_list_negara">
													{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
												</template>
												<template #selected-option="value_list_negara">
													{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
												</template>
											</v-select>
										</td>
									</tr>
									<tr>
										<th style="width: 250px;"><label for=""><b>Nama Produsen <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td><input type="text" class="form-control" placeholder="Nama Produsen" v-model="pr_nama_produsen"></td>
									</tr>
									<tr>
										<th style="width: 250px;"><label for=""><b>Negara Pengirim <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td>
											<v-select :options="list_negara" label="n_nama" v-model="negara_pengirim" placeholder="Pilih Negara Pengirim">
												<template v-slot:option="value_list_negara">
													{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
												</template>
												<template #selected-option="value_list_negara">
													{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
												</template>
											</v-select>
										</td>
									</tr>
									<tr>
										<th style="width: 250px;"><label for=""><b>Nama Pengirim <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td><input type="text" class="form-control" placeholder="Nama Pengirim" v-model="pr_nama_pengirim"></td>
									</tr>
									<tr>
										<th style="width: 250px;"><label for=""><b>Alamat Pengirim <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td><textarea rows="5" class="form-control" placeholder="Alamat Pengirim" v-model="pr_alamat_pengirim"></textarea></td>
									</tr>
									<tr>
										<th style="width: 250px;"><label for=""><b>Pekerjaan Pengirim <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td><input type="text" class="form-control" placeholder="Pekerjaan Pengirim" v-model="pr_pekerjaan_pengirim"></td>
									</tr>
									
									<tr>
										<th style="width: 250px;"><label for=""><b>Tempat Pemasukan <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td>
											<v-select :filter="searchPelabuhan" :options="list_pelabuhan" label="pl_nama" v-model="pelabuhan" placeholder="Pilih Tempat Pemasukan">
												<template v-slot:option="value_list_pelabuhan">
													{{ value_list_pelabuhan.pl_code }} - {{ value_list_pelabuhan.pl_nama }}
												</template>
												<template #selected-option="value_list_pelabuhan">
													{{ value_list_pelabuhan.pl_code }} - {{ value_list_pelabuhan.pl_nama }}
												</template>
											</v-select>
										</td>
									</tr>
									<tr>
										<th style="width: 250px;"><label for=""><b>Tujuan Pemasukan Benih <span class="text-danger">*</span></b></label></th>
										<td style="width: 15px;"> : </td>
										<td>
											
												<!-- <input type="text" class="form-control text-capitalize" placeholder="Tujuan Pemasukan Benih" v-model="tujuan_pemasukan" v-if="kategori_module == 'Pemasukan SDG Tanaman'"> -->
												<input type="text" class="form-control text-capitalize" placeholder="Tujuan Pemasukan Benih" v-model="tujuan_pemasukan " readonly="">
                                            
										</td>
									</tr>
									
								</tbody>
							</table>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md="12">
					<CCard class="top-radius-15">
						<CCardHeader class="top-radius-15 bg-success text-white">
							<h4 class="my-0 text-center"><b>Dokumen Persyaratan</b></h4>
						</CCardHeader>
						<CCardBody> 
							<CRow>
								<CCol md="6" v-for="(value_list_document, index_list_document) in list_document" :key="index_list_document">
									<CCard style="border-radius: 15px;">
										<CCardBody> 
											<b class="text-capitalize">{{index_list_document+1}}. {{value_list_document.nama_dpp}} <span v-if="value_list_document.is_required" class="text-danger">*</span></b>
											<!-- <div v-if="value_list_document.nama_dpp == 'Surat Permohonan'" v-on:click="downloadSurat"><span class="badge badge-info p-1 float-right"><i class="fa fa-download"></i> Download Surat Permohonan </span></div> -->
											<template>
                                                <a :href="'assets-download/Surat Permohonan Pemasukan '+module+'.pdf'" target="_blank" v-if="value_list_document.nama_dpp == 'Surat Permohonan'">
                                                    <span class="badge badge-info p-1 float-right"><i class="fa fa-download"></i> Contoh Surat Permohonan</span>
                                                </a>
                                                <a href="assets-download/Format Proposal Pengajuan SIP Mentan SDG Tanaman.docx" v-if="value_list_document.nama_dpp == 'Proposal Penelitian'">
                                                    <span class="badge badge-info p-1 float-right"><i class="fa fa-download"></i> Format Proposal Penelitian</span>
                                                </a>
                                            </template>
											<br>
											<input type="file" class="form-control mb-0" :id="'files-doc-'+index_list_document" v-on:change="inputFileDocument(index_list_document)">
											<div v-if="value_list_document.text_dpp_info"><small>*) {{value_list_document.text_dpp_info}} </small></div>
											<div class="respond-input-file float-left" :id="'docloading-'+index_list_document"></div>
											<small class="float-right">*) Max File 2 MB</small>
										</CCardBody>
									</CCard>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				<div class="col-4 text-center">
					<router-link to="/pemohon/dashboardpemohon" class="btn btn-warning btn-block text-white btn-hover-outline-warning btn-lg">
						<i class="fa fa-chevron-left"></i>Kembali
					</router-link>
				</div>
				<div class="col-4 text-center">
					<div class="btn btn-info btn-block text-white btn-hover-outline-info btn-lg" v-on:click="submitPermohonan('draft')"><i class="fa fa-bookmark-o"></i> Simpan Draft</div>
				</div>
				<div class="col-4 text-center" v-if="is_open">
					<div class="btn btn-success btn-block text-white btn-hover-outline-success btn-lg" v-on:click="modal_detail = true"><i class="fa fa-paper-plane-o"></i> Ajukan Permohonan</div>
				</div>
			</CRow>
			
		</div>
		<template>
            <div>
                <CModal
                    title="Preview Permohonan"
                    color="btn-primary"
                    :show.sync="modal_detail"
                    size="xl"
                >
                    <table class="w-100" border="0">
						<tr>
							<th style="width: 250px">No. Surat Permohonan </th>
							<td style="width: 15px"> : </td>
							<td>{{pr_nomor_surat}} </td>
						</tr>
						<tr>
							<th style="width: 250px">Tanggal Surat </th>
							<td style="width: 15px"> : </td>
							<td>{{pr_tanggal}} </td>
						</tr>
						<tr>
							<th style="width: 250px">Perihal </th>
							<td style="width: 15px"> : </td>
							<td>{{pr_perihal}}</td>
						</tr>
						<tr v-if="penanggung_jawab">
							<th style="width: 250px">Penanggung Jawab Surat Keputusan</th>
							<td style="width: 15px"> : </td>
							<td>{{penanggung_jawab.nama_penanggung_jwb}} ({{penanggung_jawab.jabatan_penanggung_jwb}})</td>
						</tr>


						<tr>
							<th style="width: 250px">Perlakuan Fisik/Kimia </th>
							<td style="width: 15px"> : </td>
							<td>{{pr_perlakuan}} </td>
						</tr>
						<tr>
							<th style="width: 250px">Negara Asal Benih </th>
							<td style="width: 15px"> : </td>
							<td>{{negara_asal.n_nama}} </td>
						</tr>
						<tr>
							<th style="width: 250px">Nama Produsen </th>
							<td style="width: 15px"> : </td>
							<td>{{pr_nama_produsen}} </td>
						</tr>
						<tr>
							<th style="width: 250px">Negara Pengirim </th>
							<td style="width: 15px"> : </td>
							<td>{{negara_pengirim.n_nama}} </td>
						</tr>
						<tr>
							<th style="width: 250px">Nama Pengirim </th>
							<td style="width: 15px"> : </td>
							<td>{{pr_nama_pengirim}} </td>
						</tr>
						<tr>
							<th style="width: 250px">Alamat Pengirim </th>
							<td style="width: 15px"> : </td>
							<td>{{pr_alamat_pengirim}} </td>
						</tr>
						<tr>
							<th style="width: 250px">Pekerjaan Pengirim </th>
							<td style="width: 15px"> : </td>
							<td>{{pr_pekerjaan_pengirim}} </td>
						</tr>
						
						<tr>
							<th style="width: 250px">Tempat Pemasukan </th>
							<td style="width: 15px"> : </td>
							<td>{{pelabuhan.pl_code}} - {{pelabuhan.pl_nama}} </td>
						</tr>
						
						<tr>
							<th style="width: 250px">Tujuan Pemasukan Benih </th>
							<td style="width: 15px"> : </td>
							<td>{{tujuan_pemasukan}} </td>
						</tr>
						
						
						<tr>
							<th style="width: 250px">Data benih </th>
							<td style="width: 15px"> : </td>
							<td> </td>
						</tr>
					</table>
                    <table class="table table-bordered table-sm w-100" id="table-benih" >
                        <thead class="bg-sapphire">
                            <tr>
                                <th style="width: 15px">No</th>
                                <th>Jenis Tanaman</th>
                                <th>Nama Latin</th>
                                <th>Hibrida/Inbrida/Komposit</th>
                                <th>Nama Varietas</th>
                                
                                <th>Bentuk Benih</th>
                                <th>Jumlah</th>
                                
                                <th>Satuan</th>
                                <th>Nilai <span>(Total Harga dalam USD)</span></th>
                                <th>Kode HS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value_benih, index_benih) in rows_benih_raw" :key="index_benih">
                                <td>{{index_benih+1}}</td>
                                <td>{{value_benih.tanaman_id.t_nama}}</td>
                                <td>{{value_benih.pb_nama_latin}}</td>
                                <td>{{value_benih.pb_hibrida_inbrida_komposit}}</td>
                                <td>{{value_benih.pb_varietas}}</td>
                                
                                <td><div v-if="value_benih.bentuk_benih_id">{{value_benih.bentuk_benih_id.bb_nama}}</div></td>
                                <td>{{value_benih.pb_jumlah}}</td>
                                <td><div v-if="value_benih.satuan_id">{{value_benih.satuan_id.s_nama}}</div></td>
                                <td>{{value_benih.pb_nilai_total}}</td>
                                <td>{{value_benih.pb_kode_hs}}</td>
                            </tr>
                        </tbody>
                    </table>
					<table class="table table-bordered table-sm" v-if="tujuan_pemasukan_id == 3">
						<thead class="bg-sapphire">
							<tr>
								<th>Nama Kelompok Tani</th> 
								<th>NIK</th> 
								<th>NO. HP</th> 
								<th>Titik Koordinat</th> 
								<th>Keb Benih</th> 
								<th>Luas Lahan</th>
								
							</tr>
						</thead>
						<tbody>
							<tr v-for="(value_row_kelompok_tani, key_row_kelompok_tani) in row_kelompok_tani" :key="key_row_kelompok_tani">
								<td>
									{{value_row_kelompok_tani.nama_kelompok_tani}}
								</td> 
								<td>
									{{value_row_kelompok_tani.nik}}
								</td> 
								<td>
									{{value_row_kelompok_tani.no_hp}}
								</td> 
								<td>
									{{value_row_kelompok_tani.titik_koordinat}}
								</td> 
								<td>
									{{value_row_kelompok_tani.kebun_benih}}
								</td> 
								<td>
									{{value_row_kelompok_tani.luas_lahan}}
								</td> 
								
							</tr>
						</tbody>
					</table>
                    <b>Disclaimer</b>
                    <ol type="1">
                        <li v-for="(value_rows_disclaimer, key_rows_disclaimer) in disclaimer_permohonan" :key="key_rows_disclaimer">{{value_rows_disclaimer.disclaimer_label}}</li>
                    </ol>
                    <hr>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="setuju_permohonan">
                        <label class="form-check-label" for="exampleCheck1">{{checklist_permohonan}}</label>
                    </div>
                    <template #footer>
                        <CButton @click="modal_detail = false" color="secondary">Tutup</CButton>
                        <CButton v-on:click="submitPermohonan('ajukan')" color="primary" v-if="setuju_permohonan" class="btn btn-success text-white btn-hover-outline-success"><i class="fa fa-paper-plane-o"></i> Ajukan Permohonan</CButton>
                    </template>
                </CModal>
            </div>
        </template>
	</div>
</template> 
<script>
	export default {
		name: "FormPengajuan", 
		data() {
            return {
				disclaimer_permohonan: [],
                rows_benih_raw: [],
				list_tanaman_kategori_sub: [],
		        list_negara: [],
		        list_tanaman: [],
		        list_pelabuhan: [],
		        list_document: [],
		        permohonan_dokumen: [],
		        list_bentuk_benih: [],
				list_satuan: [],
		        negara_asal: '',
		        negara_pengirim: '',
		        pelabuhan: '',
		        module: this.$route.query.label_selected_module,
		        kategori_module: this.$route.query.data_selected_kategori,
		        kategori_id_module: this.$route.query.data_selected_kategori_id,
		        tujuan_pemasukan: this.$route.query.tujuan_value,
		        tujuan_pemasukan_id: this.$route.query.tujuan_id,
				tanaman_kategori_id: this.$route.query.tanaman_kategori_id,
		        // Form input
		        pr_nomor_surat: '',
				tanaman_kategori_sub: '',
		        pr_tanggal: '',
		        pr_perihal: "Permohonan Izin Pemasukan Benih "+this.$route.query.label_selected_module+" ke Dalam Wilayah RI",
		        pr_perlakuan: '',
				pr_nama_produsen: '',
				negara_pengirim_id: '',
				pr_nama_pengirim: '',
				pr_alamat_pengirim: '',
				pr_pekerjaan_pengirim: '',
				pr_keterangan: '',
				session_data: JSON.parse(this.session),
				is_open: false,
				pr_cara_pengiriman: '',
				sdg_komoditas: [],
                sdg_varietas_klon_hibrida:'',
                sdg_bentuk_benih: [],
                sdg_banyaknya:'',
                sdg_satuan: [],
				sdg_kode_hs: [],
				list_penanggung_jawab: [],
				penanggung_jawab: [],
				modal_detail: false,
				setuju_permohonan: false,
				row_kelompok_tani: [],
				is_sayur_kentang: false,
            };
        },
		methods: {
			searchNegara(options, search) {
		      const fuse = new Fuse(options, {
		        keys: ["n_inisial", "n_nama"],
		        shouldSort: true
		      });
		      return search.length
		        ? fuse.search(search).map(({ item }) => item)
		        : fuse.list;
		    },
		    searchPelabuhan(options, search) {
		      const fuse = new Fuse(options, {
		        keys: ["pl_code", "pl_nama"],
		        shouldSort: true
		      });
		      return search.length
		        ? fuse.search(search).map(({ item }) => item)
		        : fuse.list;
		    },
			redirectList(){
                this.$router.push({name: 'PermohonanPemohonProses'});
            },
			inputFileDocument(keys){
      const inputFile = $("#files-doc-" + keys)[0];
      if (inputFile.files.length > 0) {
        const file = inputFile.files[0];
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();
        const maxSize = 2242880; // 2 MB in bytes

        if (fileExtension !== "pdf") {
          alert("Hanya file PDF yang diperbolehkan.");
          inputFile.value = ""; // Reset input file jika bukan PDF
          return;
        }

        if (file.size > maxSize) {
          alert("Maksimum ukuran file adalah 2 MB.");
          inputFile.value = ""; // Reset input file jika file terlalu besar
          return;
        }

        // Konfirmasi dari pengguna sebelum mengupload ulang
        if (
          this.permohonan_dokumen[keys] &&
          this.permohonan_dokumen[keys].isUploaded
        ) {
          if (
            confirm("File yang ada akan diganti. Anda yakin ingin melanjutkan?")
          ) {
            this.uploadFile(inputFile, file, keys);
          }
        } else {
          this.uploadFile(inputFile, file, keys);
        }
      }
    },

    uploadFile(inputElement, file, keys) {
      // Pastikan objek permohonan_dokumen untuk keys sudah ada
      if (!this.permohonan_dokumen[keys]) {
        this.permohonan_dokumen[keys] = {};
      }

      // Tampilkan loading saat memulai proses upload
      $("#docloading-" + keys).html(
        '<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
      );

      var FormData = require("form-data");
      var data = new FormData();
      data.append("file", file);
      var config = {
        method: "post",
        url: this.apiLink + "api/permohonan/uploads",
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          console.log("Response from server:", response.data);
          if (response.data && response.data.data && response.data.data.file) {
            let permohonan_dokumen_in = this.permohonan_dokumen[keys];
            permohonan_dokumen_in.file = response.data.data.file;
            const urlres = this.apiLink + response.data.data.url;
            $("#docloading-" + keys).html(
              '<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>'
            );
            this.permohonan_dokumen[keys] = permohonan_dokumen_in;
            this.permohonan_dokumen[keys].isUploaded = true;
            inputElement.value = ""; // Optionally clear input after successful upload
          } else {
            throw new Error(
              "File upload failed, server did not return file data"
            );
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          alert("Terjadi kesalahan saat mengupload file: " + error.message);
          $("#docloading-" + keys).html(
            '<span class="badge badge-danger p-1"><i class="fa fa-times"></i> Gagal </span>'
          );
          inputElement.value = ""; // Reset on failure
        });
    },
    // inputFileDocument(keys) {
    //   if ($("#files-doc-" + keys)[0].files[0]) {
    //     if ($("#files-doc-" + keys)[0].files[0].size < 2242880) {
    //       $("#docloading-" + keys).html(
    //         '<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
    //       );
    //       var permohonan_dokumen_in = this.permohonan_dokumen[keys];
    //       var urlres = this.apiLink;
    //       var FormData = require("form-data");
    //       var data = new FormData();
    //       data.append("file", $("#files-doc-" + keys)[0].files[0]);
    //       var config = {
    //         method: "post",
    //         url: this.apiLink + "api/permohonan/uploads",
    //         headers: {
    //           Authorization: "Bearer " + this.access_token,
    //         },
    //         data: data,
    //       };
    //       axios(config).then(function (response) {
    //         permohonan_dokumen_in.file = response.data.data.file;
    //         urlres += response.data.data.url;
    //         $("#docloading-" + keys).html(
    //           '<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
    //             urlres +
    //             '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>'
    //         );
    //       });
    //       this.permohonan_dokumen[keys] = permohonan_dokumen_in;
    //     } else {
    //       alert("Max File 2 MB");
    //     }
    //   }
    // },
			submitPermohonan(saveas){
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon tunggu sebentar...",
					showConfirmButton: false,
				});
                var rows_benih = [];
				let required_form = new Promise(function(resolve, reject) {
					// cek required
	                if (self.rows_benih_raw.length == 0 && saveas != 'draft') {
	                    Swal.fire({
	                        icon: 'error',
	                        title: 'Pastikan Data Benih Terisi',
	                    });
	                    resolve("stop");
	                }
	                
	                if (self.module == 'Sumber Daya Genetik') {
                        if (
                            (
                                self.pr_cara_pengiriman == '' || self.pr_cara_pengiriman == null
                            ) && saveas != 'draft'
                        ) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Pastikan Data Terisi Lengkap',
                            });
                            resolve("stop");
                        }
						$.each(self.rows_benih_raw, function(index, val) {
							if (
								(
									// val.tanaman_id == '' || val.tanaman_id == null ||
									// val.pb_nama_latin == '' || val.pb_nama_latin == null ||
									val.pb_varietas == '' || val.pb_varietas == null ||
									// val.bentuk_benih_id == '' || val.bentuk_benih_id == null ||
									val.pb_jumlah == '' || val.pb_jumlah == null ||
									// val.satuan_id == '' || val.satuan_id == null ||
									// val.pb_kode_hs == '' || val.pb_kode_hs == null ||
									val.pb_nilai_total == '' || val.pb_nilai_total == null
								)
							) {
								Swal.fire({
									icon: 'error',
									title: 'Pastikan Data Benih Terisi',
								});
								resolve("stop");
							}
							else{
								rows_benih.push({
									tanaman_id: '',
									pb_nama_umum: '-',
									pb_nama_latin: '',
									pb_varietas: val.pb_varietas,
									pb_nama_induk: '-',
									bentuk_benih_id: '',
									pb_jumlah: val.pb_jumlah,
									satuan_id: '',
									pb_nilai_total: val.pb_nilai_total,
									pb_kode_hs: '',
									is_aropt: true,
									pb_hibrida_inbrida_komposit: val.pb_hibrida_inbrida_komposit,
								});
							}
						});
                    }
                    else{
                        if (
                            (
                                self.pr_perlakuan == '' || self.pr_perlakuan == null ||
                                self.pr_nama_produsen == '' || self.pr_nama_produsen == null
                                
                            ) && saveas != 'draft'
                        ) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Pastikan Data Terisi Lengkap',
                            });
                            resolve("stop");
                        }
						$.each(self.rows_benih_raw, function(index, val) {
							if (
								(
									val.tanaman_id == '' || val.tanaman_id == null ||
									val.pb_nama_latin == '' || val.pb_nama_latin == null ||
									val.pb_varietas == '' || val.pb_varietas == null ||
									// val.pb_nama_induk == '' || val.pb_nama_induk == null ||
									val.bentuk_benih_id == '' || val.bentuk_benih_id == null ||
									val.pb_jumlah == '' || val.pb_jumlah == null ||
									val.satuan_id == '' || val.satuan_id == null ||
									val.pb_nilai_total == '' || val.pb_nilai_total == null ||
									val.pb_kode_hs == '' || val.pb_kode_hs == null
								)
							) {
								Swal.fire({
									icon: 'error',
									title: 'Pastikan Data Benih Terisi',
								});
								resolve("stop");
							}
							else{
								rows_benih.push({
									tanaman_id: val.tanaman_id.id,
									pb_nama_umum: '-',
									pb_nama_latin: val.pb_nama_latin,
									pb_varietas: val.pb_varietas,
									pb_nama_induk: val.pb_nama_induk,
									bentuk_benih_id: val.bentuk_benih_id.id,
									pb_jumlah: val.pb_jumlah,
									satuan_id: val.satuan_id.id,
									pb_nilai_total: val.pb_nilai_total,
									pb_kode_hs: val.pb_kode_hs,
									is_aropt: true,
									pb_hibrida_inbrida_komposit: val.pb_hibrida_inbrida_komposit,
								});
							}
						});
                    }
	                if (
	                        (
	                            self.pr_nomor_surat == '' || self.pr_nomor_surat == null ||
	                            self.pr_tanggal == '' || self.pr_tanggal == null ||
	                            self.negara_asal == '' || self.negara_asal == null ||
	                            self.negara_pengirim == '' || self.negara_pengirim == null ||
	                            self.pr_nama_pengirim == '' || self.pr_nama_pengirim == null ||
	                            self.pr_alamat_pengirim == '' || self.pr_alamat_pengirim == null ||
	                            self.pr_pekerjaan_pengirim == '' || self.pr_pekerjaan_pengirim == null ||
	                            self.pelabuhan == '' || self.pelabuhan == null 
								// self.penanggung_jawab == '' || self.penanggung_jawab == null
								
	                        ) && saveas != 'draft'
	                ) {
	                    Swal.fire({
	                        icon: 'error',
	                        title: 'Pastikan Data Terisi Lengkap',
	                    });
	                    resolve("stop");
	                }
					if(self.tujuan_pemasukan_id == 3 && self.is_sayur_kentang){
						if(self.row_kelompok_tani.length <= 0){
							Swal.fire({
								icon: 'error',
								title: 'Pastikan Data Kelompok Tani Terisi',
							});
							resolve("stop");
						}
						$.each(self.row_kelompok_tani, function(index, val) {
							if (
								(
									val.nama_kelompok_tani == '' || val.nama_kelompok_tani == null ||
									val.nik == '' || val.nik == null ||
									val.no_hp == '' || val.no_hp == null ||
									val.titik_koordinat == '' || val.titik_koordinat == null ||
									val.kebun_benih == '' || val.kebun_benih == null ||
									val.luas_lahan == '' || val.luas_lahan == null
								) && saveas != 'draft'
							) {
								Swal.fire({
									icon: 'error',
									title: 'Pastikan Data Kelompok Tani Terisi',
								});
								resolve("stop");
							}
						});
					}
					
	                $.each(self.permohonan_dokumen, function(index, val) {
	                	if (
	                        (
	                            val.file == '' || val.file == null 
	                        ) && saveas != 'draft' && val.is_required
	                    ) {
	                        Swal.fire({
	                            icon: 'error',
	                            title: 'Pastikan Data Dokumen Terunggah',
	                        });
	                        resolve("stop");
	                    }
	                });
	                resolve("next");
				});
				// if (this.negara_pengirim == null) {
				// 	this.negara_pengirim = { "id": '0'};
				// }
				// if (this.pelabuhan == null) {
				// 	this.pelabuhan = { "id": '0'};
				// }
				required_form.then(
                    function (response) {
                    	if (response == 'stop') {
                			return false;
                		}
						
                    	var FormData = require('form-data');
		                var data = new FormData();
		                data.append('module_id', self.kategori_id_module);
		                data.append('pr_kategori', self.module);
		                data.append('nib', self.session_data.nib);
		                data.append('pr_nomor_surat', self.pr_nomor_surat);
		                data.append('pr_tanggal', self.pr_tanggal) ;
		                data.append('module_categories_id', self.kategori_id_module) ;
		                data.append('pr_perihal', self.pr_perihal) ;
		                data.append('pr_perlakuan', self.pr_perlakuan) ;
		                
		                data.append('pr_nama_produsen', self.pr_nama_produsen) ;
		                data.append('pr_nama_pengirim', self.pr_nama_pengirim) ;
		                data.append('pr_alamat_pengirim', self.pr_alamat_pengirim) ;
		                data.append('pr_pekerjaan_pengirim', self.pr_pekerjaan_pengirim) ;
		                
		                data.append('tujuan_penggunaan_benih_id', self.tujuan_pemasukan_id) ;
		                data.append('tujuan_penggunaan_benih_nama', self.tujuan_pemasukan) ;
		                data.append('pr_keterangan', self.pr_keterangan) ;
		                data.append('status', saveas) ;
		                data.append('permohonan_benih', JSON.stringify(rows_benih)) ;
		                data.append('permohonan_dokumen', JSON.stringify(self.permohonan_dokumen)) ;
		                data.append('is_pemasukan', 1) ;
		                data.append('perusahaan_id', self.session_data.perusahaan_id);
		                data.append('pr_cara_pengiriman', self.pr_cara_pengiriman);
						
						if(self.tanaman_kategori_sub?.id){data.append('tanaman_kategori_sub_id', self.tanaman_kategori_sub?.id);}
						if(self.sdg_komoditas?.id){data.append('sdg_komoditas_id', self.sdg_komoditas?.id);}
                        if(self.sdg_bentuk_benih?.id){data.append('sdg_bentuk_benih_id', self.sdg_bentuk_benih?.id);}
                        if(self.sdg_satuan?.id){data.append('sdg_satuan_id', self.sdg_satuan?.id);}
						if(self.negara_asal?.id){data.append('negara_asal_id', self.negara_asal?.id);}
						if(self.negara_pengirim?.id){data.append('negara_pengirim_id', self.negara_pengirim?.id);}
						if(self.pelabuhan?.id){data.append('pelabuhan_id', self.pelabuhan?.id);}

                        data.append('sdg_varietas_klon_hibrida', self.sdg_varietas_klon_hibrida);
                        data.append('sdg_banyaknya', self.sdg_banyaknya);
						data.append('sdg_kode_hs', self.sdg_kode_hs);
						data.append('json_penanggung_jawab', JSON.stringify(self.penanggung_jawab)) ;
						data.append('kelompok_tani', JSON.stringify(self.row_kelompok_tani)) ;

		                var config = {
		                  method: 'post',
		                  url: self.apiLink + "api/permohonan",
						//   timeout: 30000,
		                  headers: { 
		                    'Authorization': "Bearer " + self.access_token,
		                  },
		                  data : data
		                };
		                axios(config)
		                .then(function (response) {
		                var res_ajukan = response.data;
		                var res_ajukan_data = res_ajukan.data;
		                
		                  if (res_ajukan.meta.code != "200") {
		                        Swal.fire({
		                            icon: 'error',
		                            title: 'Oops...',
		                            text: res_ajukan.data.message
		                        });
		                    }
		                    else{
		                        if (saveas == 'draft') {
		                            let timerInterval;
		                            Swal.fire({
		                                icon: "success",
		                                title: "Berhasil",
		                                text: "Anda akan diarahkan ke halaman dashboard",
		                                timer: 2000,
		                                timerProgressBar: true,
		                                showCancelButton: false,
		                                showConfirmButton: false,
		                                didOpen: () => {
		                                    timerInterval = setInterval(() => {
		                                        const content = Swal.getContent();
		                                        if (content) {
		                                            const b = content.querySelector("b");
		                                            if (b) {
		                                                b.textContent = Swal.getTimerLeft();
		                                            }
		                                        }
		                                    }, 100);
		                                },
		                                willClose: () => {
		                                    clearInterval(timerInterval);
		                                },
		                            }).then((result) => {
		                                /* Read more about handling dismissals below */
		                                if (result.dismiss === Swal.DismissReason.timer) {
		                                    self.$router.push({name: 'DashboardPemohon'});
		                                }
		                            });
		                        }else{
		                            let timerInterval;
		                            Swal.fire({
		                                icon: "success",
		                                title: "Berhasil",
		                                text: "Permohonan anda berhasil diajukan",
		                                showConfirmButton: true,
		                            }).then((result) => {
		                                if (result.isConfirmed) {
		                                    self.$router.push({name: 'DashboardPemohon'});
		                                }
		                            });
		                            return false;
		                        }
		                    }
		                }).catch((error) => {
		                    
		                    // if (error) {
		                    //     var htmlerror = '';
		                    //     $.each(error.response.data.data.error, function(index, val) {
		                    //         htmlerror += val[0]+'<br>';
		                    //     });
		                    //     Swal.fire({
		                    //         icon: "error",
		                    //         title: "Gagal",
		                    //         html: htmlerror,
		                    //     });
		                    // }
							if (error) {
                                if(error?.response){
									var htmlerror = '';
									if (typeof error.response.data.data.error === 'string' || error.response.data.data.error instanceof String){
										var htmlerror = error.response.data.data.error;
									}
									else{
										
										$.each(error.response.data.data.error, function(index, val) {
											htmlerror += val[0]+'<br>';
										});
									}
                                    
                                
                                    Swal.fire({
                                        icon: "error",
                                        title: "Gagal",
                                        html: htmlerror
                                    });
                                }
                                else{
                                    Swal.fire({
                                        icon: "error",
                                        title: "Gagal",
                                        text: "Gagal, Silahkan cek koneksi anda !"
                                    });
                                }
		                    }
		                });
                    }
                );
				
			},
			downloadSurat(){
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon tunggu sebentar...",
					showConfirmButton: false,
				});
				var FormData = require('form-data');
				var data = new FormData();
				data.append('module_id', this.kategori_id_module);
				data.append('pr_kategori', this.module);
				data.append('nib', this.session_data.nib);
				data.append('pr_nomor_surat', this.pr_nomor_surat);
				data.append('pr_tanggal', this.pr_tanggal) ;
				data.append('module_categories_id', this.kategori_id_module) ;
				data.append('pr_perihal', this.pr_perihal) ;
				data.append('pr_perlakuan', this.pr_perlakuan) ;
				data.append('negara_asal_id', this.negara_asal) ;
				data.append('pr_nama_produsen', this.pr_nama_produsen) ;
				data.append('negara_pengirim_id', this.negara_pengirim) ;
				data.append('pr_nama_pengirim', this.pr_nama_pengirim) ;
				data.append('pr_alamat_pengirim', this.pr_alamat_pengirim) ;
				data.append('pr_pekerjaan_pengirim', this.pr_pekerjaan_pengirim) ;
				data.append('pelabuhan_id', this.pelabuhan) ;
				data.append('tujuan_penggunaan_benih_id', this.tujuan_pemasukan_id) ;
				data.append('pr_keterangan', this.pr_keterangan) ;
				data.append('permohonan_benih', JSON.stringify(this.rows_benih_raw)) ;
				data.append('is_pemasukan', 1) ;
				var config = {
				  method: 'post',
				  url: this.apiLink + "api/generate/dokumen_permohonan",
				  headers: { 
				    'Authorization': "Bearer " + this.access_token,
				  },
				  data : data
				};
				axios(config)
				.then(function (response) {
				var res_template = response.data;
				var res_template_data = res_template.data;
				  if (res_template.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_template.data.message
						});
					}
					else{
						
					}
				}).catch((error) => {
					
					if (error) {
						var htmlerror = '';
						$.each(error.response.data.data.error, function(index, val) {
							htmlerror += val[0]+'<br>';
						});
						Swal.fire({
							icon: "error",
							title: "Gagal",
							html: htmlerror,
						});
					}
				});
			},
			changeJenisTanaman(id_tanaman, key_row_tanaman){
				// var tanaman = this.list_tanaman[id_tanaman.id];
				if(id_tanaman == null){
					this.rows_benih_raw[key_row_tanaman].pb_nama_latin = "";
					return false;
				}
				// this.rows_benih_raw[key_row_tanaman].pb_nama_latin = id_tanaman.t_nama_latin;
				// var tanaman = this.list_tanaman.find(tanamans => {
				// 	if (tanamans.id === id_tanaman.id) {
				// 		return tanamans
				// 	}
				// });
				if(key_row_tanaman == 0){

					if(id_tanaman.id == 136){
						this.is_sayur_kentang = true;
					}
					else{
						this.is_sayur_kentang = false;
					}
				}
				this.rows_benih_raw[key_row_tanaman].pb_nama_latin = id_tanaman.t_nama_latin;
				this.rows_benih_raw[key_row_tanaman].is_select_hibrida = id_tanaman.is_select_hibrida;
                this.rows_benih_raw[key_row_tanaman].pb_hibrida_inbrida_komposit = '';
				// console.log(id_tanaman)
			},
			addRowBenih(){   
				this.rows_benih_raw.push({
	                tanaman_id: '',
                    pb_nama_umum: '-',
                    pb_nama_latin: '',
                    pb_varietas: '',
					pb_nama_induk: '',
                    bentuk_benih_id: '',
                    pb_hibrida_inbrida_komposit: '',
                    pb_jumlah: '',
                    satuan_id: '',
                    pb_nilai_total: '',
                    pb_kode_hs: '',
                    is_select_hibrida: false,
                    is_aropt: true,
	            });
		    },
			deleteRow(index, rows_benih_raw_at) {
	            var idx = this.rows_benih_raw.indexOf(rows_benih_raw_at);
				var tanaman = this.list_tanaman.find(tanamans => {
                    if (tanamans.id === rows_benih_raw_at.tanaman_id.id) {
                        return tanamans
                    }
                })
				
				
				console.log(tanaman);
				
				if(index == 0 && tanaman){

					if(tanaman.id == 136){
						this.is_sayur_kentang = false;
					}
					else{
						this.is_sayur_kentang = true;
					}
				}
	            if (index > -1) {
	                this.rows_benih_raw.splice(index, 1);
	            }
				console.log(this.rows_benih_raw);
	        },
			addRowKelompokTani(){   
				this.row_kelompok_tani.push({
	                nama_kelompok_tani: "",
					nik: "",
					no_hp: "",
					titik_koordinat: "",
					kebun_benih: "",
					luas_lahan: "",
	            });
		    },
			deleteRowKelompokTani(index, row_kelompok_tani) {
	            var idx = this.row_kelompok_tani.indexOf(row_kelompok_tani);
	            if (idx > -1) {
	                this.row_kelompok_tani.splice(idx, 1);
	            }
	        },
		    
		},
		mounted() {
			$(".select2").select2({
				placeholder: "-- Pilih --",
			});
		},
		created() {
			Swal.fire({
				title: '<i class="fa fa-refresh fa-spin"></i>',
				text: "Mohon tunggu sebentar...",
				showConfirmButton: false,
				allowOutsideClick: false,
			});
			
			
			// Get Sub Jenis Tanaman
			axios.get(this.apiLink + "api/master/tanaman_kategori_sub", {
                params: {
					tanaman_kategori_id: this.tanaman_kategori_id
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_tanaman_kategori_sub = response.data;
				var res_tanaman_kategori_sub_data = res_tanaman_kategori_sub.data;
				if (res_tanaman_kategori_sub.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_tanaman_kategori_sub.data.message
					});
				}
				else{
					this.list_tanaman_kategori_sub = res_tanaman_kategori_sub_data.master;
				}
			});
			// Get Master Negara
			axios.get(this.apiLink + "api/master/negara", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_negara = response.data;
				var res_negara_data = res_negara.data;
				if (res_negara.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_negara.data.message
					});
				}
				else{
					this.list_negara = res_negara_data.master;
				}
			});
			// Get Master Pelabuhan
			axios.get(this.apiLink + "api/master/pelabuhan", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_pelabuhan = response.data;
				var res_pelabuhan_data = res_pelabuhan.data;
				if (res_pelabuhan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_pelabuhan.data.message
					});
				}
				else{
					this.list_pelabuhan = res_pelabuhan_data.master;
				}
			}); 
			
            
			axios.get(this.apiLink + "api/master/dokumen_permohonan", {
                params: {
					tujuan_penggunaan_benih_id: this.tujuan_pemasukan_id
				},
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_document = response.data;
				var res_document_data = res_document.data;
				if (res_document.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_document.data.message
					});
				}
				else{
					var raw_document = res_document_data.master;
					var clear_document_inputed = [];
					var clear_document = [];
					$.each(raw_document, function(index, val) {
						clear_document.push({
						 	'id_dpp': val.dokumen_persyaratan_permohonan.id,
						 	'nama_dpp': val.dokumen_persyaratan_permohonan.dpp_nama,
						 	'is_required': val.dokumen_persyaratan_permohonan.is_required,
						 	'template_dpp_link': '',
						 	'text_dpp_info': '',
						});
						clear_document_inputed.push({
							'dokumen_permohonan_id': val.id,
							'nama_dpp': val.dokumen_persyaratan_permohonan.dpp_nama,
							'is_required': val.dokumen_persyaratan_permohonan.is_required,
							'file': ''
						});
					});
					this.permohonan_dokumen = clear_document_inputed;
					this.list_document = clear_document;
				}
			});
			// Get Master Tanaman
			axios.get(this.apiLink + "api/master/tanaman", {
				params: {
					tanaman_kategori_id: this.tanaman_kategori_id
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
			})
			.then((response) => {
				var res_tanaman = response.data;
                var res_tanaman_data = res_tanaman.data;
                if (res_tanaman.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_tanaman.data.message
                    });
                }
                else{
                    var raw_tanaman = res_tanaman_data.master;
                    var clear_tanaman = [];
                    var nama_module = this.module;
                    $.each(raw_tanaman, function(index_kategori, val_kategori) {
                        $.each(val_kategori.tanaman_kategori_sub, function(index_sub_kategori, val_sub_kategori) {
                            var sub_kategori = val_sub_kategori.tanaman_kategori_nama;
                            if (nama_module != 'Sumber Daya Genetik') {

                                if (sub_kategori.toLowerCase() == nama_module.toLowerCase()) {
                                    $.each(val_sub_kategori.tanaman, function(index_tanaman, val_tanaman) {
                                        clear_tanaman[val_tanaman.id] = val_sub_kategori.tanaman[index_tanaman];
                                    });
                                }
                            }
                            else{
                                $.each(val_sub_kategori.tanaman, function(index_tanaman, val_tanaman) {
                                    clear_tanaman[val_tanaman.id] = val_sub_kategori.tanaman[index_tanaman];
                                });
                            }
                        });
                    });
                    var clear_tanaman = clear_tanaman.filter(function (el) {
                      return el != null;
                    });
                    this.list_tanaman = clear_tanaman;
                    Swal.close();
                }
			});
			axios.get(this.apiLink + "api/master/bentuk_benih", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_bentuk_benih = response.data;
				var res_bentuk_benih_data = res_bentuk_benih.data;
				if (res_bentuk_benih.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_bentuk_benih.data.message
					});
				}
				else{
					this.list_bentuk_benih = res_bentuk_benih_data.master;
				}
			});
			axios.get(this.apiLink + "api/master/satuan", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_satuan = response.data;
				var res_satuan_data = res_satuan.data;
				if (res_satuan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_satuan.data.message
					});
				}
				else{
					this.list_satuan = res_satuan_data.master;
				}
			});
			let self = this;
			axios.get(this.apiLink + "api/crud/konfigurasi", {
                params: {
                    module_id: self.kategori_id_module
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_konfigurasi = response.data;
                var res_konfigurasi_data = res_konfigurasi.data[0];
                var res_disclaimer_data = res_konfigurasi.data[1];
                if (res_konfigurasi.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_konfigurasi.data.message
                    });
                }
                else{
                    if(res_disclaimer_data){

                        self.disclaimer_permohonan = res_disclaimer_data;
                    }
                    var open_apli = res_konfigurasi_data.find(options => {
                        if (options.id === 1) {
                            return options
                        }
                    });
                    if (open_apli.k_aksi != 'open') {
                        self.is_open = true;
                    }
                    
                    var checklist_permohonan = res_konfigurasi_data.find(options => {
                        if (options.id === 5) {
                            return options
                        }
                    });
                    if (checklist_permohonan.k_aksi) {
                        self.checklist_permohonan = checklist_permohonan.k_aksi;
                    }
                }
            });
			var json_perusahaan = JSON.parse(this.session_data.perusahaan.p_json);
			if(json_perusahaan){

				this.list_penanggung_jawab = json_perusahaan.responinqueryNIB.dataNIB.penanggung_jwb;
			
			}
		}
	};
</script>